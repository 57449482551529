.QuestionContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 5rem 0; */
  height: 100vh;
}

.CarouselQuestion {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  box-sizing: border-box;
  /* max-width: 100vw; */
}

.Question {
  font-size: 24px;
  margin-bottom: 20px;
}

.GuestAnswers {
  max-width: 500px;
}

.GuestAnswer {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 5px 0;
}

.GuestAnswer .GuestName {
  flex: 1;
  font-weight: 500;
  font-size: 18px;
}

.GuestYesNoContainer {
  display: flex;
  flex: 2;
}

.YesNoToggle input[type="radio"] {
  display: none;
}

.YesNoToggle input[type="radio"]+label {
  cursor: pointer;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.YesNoToggle input[type="radio"]+label:hover {
  background: none;
  color: #FFF;
}

.YesNoToggle input[type="radio"]+label:after {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition: left 100ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: left 100ms cubic-bezier(0.77, 0, 0.175, 1);
  width: 100%;
  z-index: -1;
}

.YesNoToggle input[type="radio"]:checked+label {
  background-color: rgb(218, 167, 141);
  border: 2px solid rgb(218, 167, 141);
  color: rgb(29, 56, 78);
}

.YesNoToggle input[type="radio"]:checked+label:after {
  left: 0;
}

.YesNoToggle label {
  height: 40px;
  width: 124px;
  font-size: 16px;
  border-radius: 2px;
  background-color: rgb(29, 56, 78);
  border: 2px solid rgb(218, 167, 141);
  color: rgb(218, 167, 141);
  opacity: 1;
  cursor: pointer;
  display: inline-block;
  appearance: button;
  -webkit-transition: background 600ms ease, color 600ms ease;
  transition: background 600ms ease, color 600ms ease;
}

.FreeText {
  font-family: "Josefin Sans";
  color: rgb(218, 167, 141);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  background-color: rgb(29, 56, 78) !important;
  border-color: rgb(218, 167, 141) !important;
  padding: 0.75rem;
  margin: 5px;
  width: 300px;
  resize: none;
}

.GuestFreeTextContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
}

.FreeTextQualified {
  composes: FreeText;
  max-width: 258px;
}

.GuestFreeTextQualifiedHelp {
  padding: 4px;
  font-size: 14px;
}

.FormControlButton {
  line-height: 2.75rem;
  min-width: 180px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  cursor: pointer;
  padding-bottom: 0px;
  padding-top: 0.125rem;
  text-align: center;
  font-family: "Josefin Sans";
  color: rgb(29, 56, 78);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2.5px;
  position: relative;
  margin-top: 20px;
  background-color: rgb(218, 167, 141);
  text-transform: uppercase;
  display: block !important;
  max-width: 500px;
  border-radius: 2px;
  transition: background-color 0.1s ease-out 0s, color, border-color;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: rgb(218, 167, 141);
}

.FormControlButton:hover {
  background-color: rgb(252, 207, 185);
  color: rgb(29, 56, 78);
}

.FormControlButton:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.FormControlButtonSecondary {
  background: none;
  border: none;
  text-transform: uppercase;
  display: block;
  max-width: 500px;
  color: rgb(218, 167, 141);
  cursor: pointer;
  line-height: 2.75rem;
}

.FormControlButtonSecondary:hover {
  color: rgb(252, 207, 185);
}

.GuestFreeTextQualifiedHelpButton {
  composes: FormControlButtonSecondary;
  display: inline;
  line-height: initial;
}

.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CompletedWarning {
  width: 400px;
  max-width: 80%;
  border: 1px solid rgb(218, 167, 141);
  padding: 20px 10px;
  line-height: 20px;
  border-radius: 5px;
  background-color: rgb(218, 167, 141, 0.1);
}

.QuestionRequired {
  display: block;
  font-size: 14px;
  margin-top: 3px;
  opacity: 0.6;
}
