.Content p {
  padding: 6px 0;
  margin: 2px 0;
  font-family: 'Josefin Sans', sans-serif;
  color: #daa78d;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .25px;
}

.ContentBlock {
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Image {
  max-width: 1160px;
  max-width: 90vw;
}

.SectionBreak {
  border-top: 1px solid rgba(218, 167, 141, 15%);
}

.Content h1 {
  line-height: 1;
  margin: 32px 0 8px 0;
  font-family: Josefin Sans;
  color: #daa78d;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.Content h2 {
  margin: 8px 0;
  font-family: Josefin Sans;
  color: #daa78d;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.PageLink {
  line-height: 2.75rem;
  min-width: 180px;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  cursor: pointer;
  padding-bottom: 0px;
  padding-top: 0.125rem;
  text-align: center;
  font-family: "Josefin Sans";
  color: rgb(29, 56, 78);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2.5px;
  margin-top: 20px;
  background-color: rgb(218, 167, 141);
  text-transform: uppercase;
  max-width: 500px;
  border-radius: 2px;
  transition: background-color 0.1s ease-out 0s, color, border-color;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-color: rgb(218, 167, 141);
  display: inline-block;
  text-decoration: none;
}

.PageLink:hover {
  background-color: rgb(252, 207, 185);
  color: rgb(29, 56, 78);
}

.QuestionList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
}

.Question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Answer {
  padding: 0px;
  max-width: 550px;
  line-height: 1.75;
  margin: 32px 0px;
  font-family: "Josefin Sans";
  color: rgb(218, 167, 141);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.25px;
  white-space: pre-line;
}

.Answer p {
  padding: 0;
  margin: 0;
}
