@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@500&family=Parisienne&display=swap');

html {
  background-color: #1d384e;
  text-align: center;
  color: #daa78d;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

h1,
.Header1 {
  font-family: 'Parisienne', cursive;
  line-height: 1;
  margin: 16px 0;
  font-family: 'Parisienne', cursive;
  color: #daa78d;
  font-size: 66px;
  font-weight: 400;
  padding-bottom: 20px;
}

h3,
.Header3 {
  line-height: 1;
  margin: 8px 0;
  font-family: 'Josefin Sans', sans-serif;
  color: #daa78d;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

h4,
.Header4 {
  margin: 24px 0px 0px;
  text-align: center;
  font-family: "Josefin Sans";
  color: rgb(218, 167, 141);
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.Footer {
  padding-top: 30px;
  padding-bottom: 30px;

}

.Footer .Header1 {
  border-bottom: 1px solid #daa78d;
}

main {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
}

a {
  color: #daa78d;
  text-decoration: underline;
}

a:hover {
  color: rgb(252, 207, 185);
}

@media (max-width: 576px) {
  main {
    max-width: 80%;
  }

  h1,
  h2,
  h3,
  h4 {
    max-width: 85%;
  }
}
