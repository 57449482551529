.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.HeaderImageContainer {
  max-width: 100%;
  max-height: 120px;
  margin-bottom: 20px;
  width: 100%;
}

.HeaderImage {
  width: 100%;
  max-width: 100%;
  height: auto;
}

@media (max-width: 576px) {
  .HeaderImageContainer {
    max-height: 10px;
  }
}
