.MenuToggle {
  display: none;
  position: absolute;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  top: 0;
  left: 0;
}

.MenuToggle input {
  display: block;
  width: 55px;
  height: 50px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  -webkit-touch-callout: none;
}

.MenuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #daa78d;
  margin-left: 1.5rem;
  top: 1.5rem;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;
}

.MenuToggle span:first-child {
  transform-origin: 0% 0%;
}

.MenuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.MenuToggle input:checked~span {
  opacity: 0;
  background: #daa78d;
  margin-left: 1.5rem;
}

.MenuToggle input:checked~span:nth-last-child(2),
.MenuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
}

.MenuToggle input:checked~.SlideMenu {
  transform: translate(0, 0);
}

.SlideMenu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: none;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  top: 0;
  left: 0;
  background-color: #122230;
  padding: 1rem;
  transform: translate(-100%, 0);
}

.SlideMenuNavLinks {
  margin-bottom: 1rem;
  padding-top: 3.5rem;

}

.SlideMenuNavLinks li {
  padding: 1rem 0;
  font-size: 24px;
}

.SlideMenuNavLinks li a {
  color: #daa78d;
  text-decoration: none;
}

.Nav {
  padding-top: 0;
  padding-bottom: 25px;
}

.Menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
}

.MenuItem,
.MenuItemActive {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: .25px;
  border-bottom: 2px solid transparent;
  display: block;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0 auto;
  padding: 8px 8px 6px;
  transition: all 0.25s;
}

.MenuItemActive {
  border-bottom: 2px solid #daa78d;
}

.MenuItem a,
.MenuItemActive a {
  font-family: Josefin Sans;
  color: #daa78d;
  text-decoration: none;
}


@media (max-width: 576px) {
  .SlideMenu {
    /* margin-left: calc(1.5rem + 33px + 1px); */
  }

  .MenuToggle {
    display: block;
  }

  .Menu {
    display: none;
  }

  .Nav {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

html:has(.MenuToggle input:focus) {
  overflow-y: hidden;
}

body:has(.MenuToggle input:focus) {
  overflow-y: hidden;
}
